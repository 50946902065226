import React, { useState } from 'react'
import context from 'react-bootstrap/esm/AccordionContext'
import { initialState as journalInitialState } from "../components/guideAdmin/journal/context/context"
import GuideAdminContext from "./GuideAdminContext"

const GuideAdminProvider = props => {
	const defaultFilterState = {
		page: 1,
		type: 'all',
		limit: 25,
		sort: ['date'],
		sort_type: ['desc'],
		rating: ['all'],
		data: ['all'],
		search: ''
	};
	const [tripsByGuide, setTripsByGuide] = useState([]);
	const [guideTrips, setGuideTrips] = useState([]);
	const [guideManagerList, setGuideManagerList] = useState([]);
	const [reviewList, setReviewList] = useState([]);
	const [allReviewsList, setAllReviewsList] = useState([]);
	const [reviewSummary, setReviewSummary] = useState([]);
	const [reviewAllRecords, setReviewAllRecords] = useState([]);
	const [reviewMetadata, setReviewMetadata] = useState([]);
	const [reviewFilters, setReviewFilters] = useState(defaultFilterState);
	const [pinnedReviews, setPinnedReviews] = useState([]);
	const [reviewLoader, setReviewLoader] = useState({ 'summary': false, 'list': false, 'filter': false })
	const [pinnedList, setPinnedList] = useState([]);
	const [skippedReviewList, setSkippedReviewList] = useState([]);
	const [selectedCustomer_ref, setSelectedCustomer_ref] = useState([]);
	const [selectedReview_ref, setSelectedReview_ref] = useState([]);
	const [reviewsData, setReviewsData] = useState([]);
	const [guideCount, setGuideCount] = useState(0);
	const [daydata, setDayData] = useState([]);
	const [futureDates, setFutureDates] = useState([]);
	const [pastDates, setPastDates] = useState([]);
	const [noTrips, setNoTrips] = useState([]);
	const [disableDates, setDisableDates] = useState([]);
	const [loadingMask, setLoadingMask] = useState(false);
	const [loadingdelete, setLoadingDelete] = useState(false);
	const [guideScheduleCal, setGuideScheduleCal] = useState([]);
	const [apicount, setApiCount] = useState(0);
	const [trips, setTrips] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [allratecards, setAllratecards] = useState([]);
	const [customRateCards, setCustomRateCards] = useState({});
	const [editRatecard, setEditRateCard] = useState(false);
	const [selectedRateCard, setSelectedRateCard] = useState(undefined);
	const [checkedRateCard, setCheckedRateCard] = useState(null);
	const [checked, setChecked] = useState(false);
	const [river, setRiver] = useState([]);
	const [ratestatus, setRatestatus] = useState("");
	const [rate_description, setDescrption] = useState("");
	const [selectedBook_ref, setSelectedBook_ref] = useState({});
	const [guestselectedBook_ref, setguestselectedBook_ref] = useState({});
	const [adjustLog, setAdjustLog] = useState([]);
	const [newratecards, setNewRateCards] = useState([]);
	const [selectedUpcoming, setSelectedUpcoming] = useState(false);
	const [selectedPast, setSelectedPast] = useState(false);
	const [selectedCancel, setSelectedCancel] = useState(false);
	const [selectedReviewGiven, setSelectedReviewGiven] = useState(false);
	const [selectedTestimonials, setSelectedTestimonials] = useState(false);
	const [selectedReviewReceived, setSelectedReviewReceived] = useState(false);
	const [reviewsReceived, setReviewsReceived] = useState([]);
	const [reviewsGiven, setReviewsGiven] = useState([]);
	const [testimonialsList, setTestimonialsList] = useState([]);
	const [notifications, setNotifications] = useState(true);
	const [messages, setMessages] = useState(false);
	const [guideSchedule, setGuideSchedule] = useState([]);
	const [BGguideSchedule, setBGguideSchedule] = useState([]);
	const [guideHolidays, setGuideHolidays] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [noBooking, setNoBooking] = useState(false)
	const [secondTip, setSecondTip] = useState(0);
	const [formData, setFormData] = useState({});
	const [updatedTotal, setUpdatedTotal] = useState("");
	const [totalBookingRef, setTotalBookingRef] = useState("");
	const [menubarCount, setMenuBarCount] = useState(0);
	const [menubar, setMenuBar] = useState(true);
	const [journalData, setJournalData] = useState(journalInitialState);
	const [companyAllGuides, setComapanyAllGuides] = useState([]);
	const [guideFilters, setGuideFilters] = useState([11, 12]);
	const [unseenReplyIds, setUnseenReplyIds] = useState([]);
	const [showHolidayModal, setShowHolidayModal] = useState(false)
	const [parentBooking, setParentBooking] = useState(true)
	const [showCancelTrip, setShowCancelTrip] = useState(false)
	const [activeBookingFromPOS, setActiveBookingFromPOS] = useState()
	const [sub_permissions, setSub_permissions] = useState([])
	const [toggleSettings, setToggleSettings] = useState(false)
	const [toggleMonth, setToggleMonth] = useState(false)
	const [scheduleDates, setScheduleDates] = useState({})
	const [companySetting, setCompanySetting] = useState({})
	const [invites, setInvites] = useState([])
	const [showMailToast, setShowMailToast] = useState(false)
	const [newReport, setNewReport] = useState(false)
	const [currentGuideValue, setCurrentGuideValue] = useState()
	const [selectTimeAdj, setSelectTimeAdj] = useState('')
	const [isModalOpen, setModalOpen] = useState(false);
	const [accountList, setAccountList] = useState([])
	const [isSummaryRep, setIsSummaryRep] = useState(false)
	const [provider, setProvider] = useState('')
	const [isError, setError] = useState('')
	const [guestLoading, setGuestLoading] = useState(false)
	const [historyLoad, setHistoryLoad] = useState(false)
	const [selectedChildBook_ref, setSelectedChildBook_ref] = useState(false);
	const [updatedChildBook_ref, setUpdatedChildBook_ref] = useState(false);
	return (
		<GuideAdminContext.Provider
			value={{
				selectedUpcoming,
				setSelectedUpcoming,
				selectedPast,
				setSelectedPast,
				selectedCancel,
				setSelectedCancel,
				guideCount,
				setGuideCount,
				guideTrips,
				setGuideTrips,
				guideManagerList,
				setGuideManagerList,
				reviewList,
				setReviewList,
				allReviewsList,
				reviewSummary,
				reviewAllRecords,
				reviewMetadata,
				reviewFilters,
				reviewLoader,
				newReport, setNewReport,
				pinnedReviews,
				defaultFilterState,
				setReviewAllRecords,
				setReviewMetadata,
				setReviewSummary,
				setReviewFilters,
				setReviewLoader,
				setAllReviewsList,
				setPinnedReviews,
				pinnedList,
				setPinnedList,
				testimonialsList,
				setTestimonialsList,
				skippedReviewList,
				setSkippedReviewList,
				selectedCustomer_ref,
				setSelectedCustomer_ref,
				selectedReview_ref,
				setSelectedReview_ref,
				tripsByGuide,
				setTripsByGuide,
				reviewsReceived,
				setReviewsReceived,
				reviewsGiven,
				setReviewsGiven,
				selectedReviewGiven,
				setSelectedReviewGiven,
				selectedReviewReceived,
				setSelectedReviewReceived,
				selectedTestimonials,
				setSelectedTestimonials,
				daydata,
				setDayData,
				futureDates,
				setFutureDates,
				pastDates,
				setPastDates,
				noTrips,
				setNoTrips,
				disableDates,
				setDisableDates,
				loadingMask,
				setLoadingMask,
				loadingdelete, setLoadingDelete,
				apicount,
				setApiCount,
				trips,
				setTrips,
				customRateCards,
				setCustomRateCards,
				editRatecard,
				setEditRateCard,
				selectedRateCard,
				setSelectedRateCard,
				showModal,
				setShowModal,
				checkedRateCard,
				setCheckedRateCard,
				checked,
				setChecked,
				river,
				setRiver,
				ratestatus,
				setRatestatus,
				rate_description,
				setDescrption,
				allratecards,
				setAllratecards,
				newratecards,
				setNewRateCards,
				selectedBook_ref,
				setSelectedBook_ref,
				guestselectedBook_ref,
				setguestselectedBook_ref,
				guideSchedule,
				setGuideSchedule,
				guideHolidays,
				setGuideHolidays,
				selectedDate,
				setSelectedDate,
				notifications,
				setNotifications,
				messages,
				setMessages,
				secondTip,
				setSecondTip,
				// newCard, setNewCard,
				adjustLog,
				setAdjustLog,
				formData,
				setFormData,
				updatedTotal,
				setUpdatedTotal,
				totalBookingRef,
				setTotalBookingRef,
				journalData,
				setJournalData,
				menubarCount, setMenuBarCount,
				menubar, setMenuBar,
				reviewsData, setReviewsData,
				companyAllGuides, setComapanyAllGuides,
				guideFilters, setGuideFilters,
				unseenReplyIds, setUnseenReplyIds,
				BGguideSchedule, setBGguideSchedule,
				showHolidayModal, setShowHolidayModal,
				noBooking, setNoBooking,
				parentBooking, setParentBooking,
				showCancelTrip, setShowCancelTrip,
				activeBookingFromPOS, setActiveBookingFromPOS,
				sub_permissions, setSub_permissions,
				toggleSettings, setToggleSettings,
				toggleMonth, setToggleMonth,
				scheduleDates, setScheduleDates,
				companySetting, setCompanySetting,
				invites, setInvites, showMailToast, setShowMailToast,
				currentGuideValue, setCurrentGuideValue,
				selectTimeAdj, setSelectTimeAdj,
				guideScheduleCal, setGuideScheduleCal,
				isModalOpen, setModalOpen,
				accountList, setAccountList,
				isSummaryRep, setIsSummaryRep,
				provider, setProvider,
				isError, setError,
				guestLoading, setGuestLoading,
				historyLoad, setHistoryLoad,
				selectedChildBook_ref, setSelectedChildBook_ref,
				updatedChildBook_ref, setUpdatedChildBook_ref
			}}
		>
			{props.children}
		</GuideAdminContext.Provider>
	);
};
export default GuideAdminProvider

