/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import MapProvider from "./src/context_provider/MapProvider"
import BeaGuideProvider from "./src/context_provider/BeaGuideProvider"
import UserProvider from "./src/context_provider/UserProvider"
import GuideAdminProvider from "./src/context_provider/GuideAdminProvider"
import ErrorBoundary from "./src/components/ErrorBoundary"
import GuideProvider from "./src/context_provider/GuideProvider";
import SearchProvider from "./src/context_provider/SearchProvider"
import QuickBookProvider from "./src/context_provider/QuickBookProvider"
import { onLCP, onINP, onCLS, onTTFB, onFCP } from 'web-vitals';

export const disableCorePrefetching = () => true

export const wrapRootElement = ({
	element
}) => (
	<UserProvider >
		<MapProvider >
			<GuideProvider>
				<BeaGuideProvider >
					<GuideAdminProvider>
						<SearchProvider>
							<QuickBookProvider>
								<ErrorBoundary>
									{element}
								</ErrorBoundary>
							</QuickBookProvider>
						</SearchProvider>
					</GuideAdminProvider>
				</BeaGuideProvider>
			</GuideProvider>
		</MapProvider>
	</UserProvider>
)

let nextRoute = ``
const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
	window.addEventListener("error", event => {
		//console.log("event...reason issue...", event, event.reason);
		if (
			(/loading chunk \d*./i.test(event.reason) ||
				/loading chunk \d*./i.test(event.message))
			&& !window.location.search.includes("e=true")
		) {
			if (nextRoute) {
				nextRoute = nextRoute.slice(0, nextRoute.lastIndexOf('/'));
				window.location.href = window.location.href + "?e=true"
			}
		}
	});
}

// please do not remove this code from here
// this is in place if you want to load here and omit from gatsby plugin
const loadHeap = () => {
	window.heap = window.heap || []

	window.heap.load = function (appId, heapConfig) {
		window.heap.appid = appId;
		window.heap.config = heapConfig || {};

		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.src = "https://cdn.heapanalytics.com/js/heap-" + appId + ".js";

		const firstScript = document.getElementsByTagName("script")[0];
		firstScript.parentNode.insertBefore(script, firstScript);

		const cloneArray = (arrayLike) => Array.prototype.slice.call(arrayLike, 0);

		const createMethod = function (method) {
			return function () {
				window.heap.push([
					method,
					...cloneArray(arguments)
				]);
			}
		};

		const methods = [
			'addEventProperties',
			'addUserProperties',
			'clearEventProperties',
			'identify',
			'resetIdentity',
			'removeEventProperty',
			'setEventProperties',
			'track',
			'unsetEventProperty',
		];

		for (let method of methods) {
			window.heap[method] = createMethod(method)
		}
	};

	window.heap.load(`${process.env.HEAP_ID}`);
}

// please do not remove this code from here
// this is in place if you want to load here and omit from gatsby plugin
const loadGTM = () => {
	(function (w, d, s, l, i) {
		w[l] = w[l] || [];
		w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s),
			dl = l != "dataLayer" ? "&l=" + l : "";
		j.async = true;
		j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
		f.parentNode.insertBefore(j, f);
	})(window, document, "script", "dataLayer", "GTM-5TNJKQW");
}

const reportWebVitals = () => {
	onLCP(sendtoHeap);
	onINP(sendtoHeap);
	onCLS(sendtoHeap);
	onTTFB(sendtoHeap);
	onFCP(sendtoHeap);
}

const sendtoHeap = (data) => {
	window.heap.track(`WebVitals-${data.name}`, data);
}

export const onClientEntry = () => {
	// loadHeap();
	// if (isBrowser) {
	// 	loadGTM();
	// 	reportWebVitals()
	// }
	setTimeout(() => {
		loadHeap();
		loadGTM();
		reportWebVitals()
	}, 0);
};
