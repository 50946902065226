import React, { useState } from "react"
import BeaGuideContext from "./BeaGuideContext";

const BeaGuideProvider = (props) => {

	const [guideDetails, setGuideDetails] = useState({})
	const [businessSuiteEnabled, setBusinessSuiteEnabled] = useState(false)
	const [statusResponse, setStatusResponse] = useState(null)
	const [currentLoc, setCurrentLoc] = useState({})
	const [currentMarker, setCurrentMarker] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [apiError, setApiError] = useState(false)
	const [searchedCity, setSearchedCity] = useState('')
	const [polyline, setPolyline] = useState(false)
	const [initiated, setInitiated] = useState(false)
	const [profile, setProfile] = useState(false)
	const [location, setLocation] = useState(false)
	const [geoLocation, setGeoLocation] = useState(null)
	const [geoLocationCp, setGeoLocationCp] = useState(null)
	const [territoryName, setTerritoryName] = useState('')
	const [loc_1, setLoc_1] = useState(false)
	const [loc_2, setLoc_2] = useState(false)
	const [loc_3, setLoc_3] = useState(false)
	const [equipments, setEquipments] = useState(false)
	const [extras, setExtras] = useState(false)
	const [ready_to_submit, setReady_to_submit] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [approved, setApproved] = useState(false)
	const [loadingMask, setLoadingMask] = useState(false)
	const [customLoadingMask, setCustomLoadingMask] = useState(false)
	const [guidePOI, setGuidePOI] = useState({})
	// Profile Info Page
	const [name, setName] = useState(null)
	const [title, setTitle] = useState('')
	const [show_title, setShowTitle] = useState(false)
	const [email, setEmail] = useState(null)
	const [phone, setPhone] = useState("")
	const [formattedPhone, setFormattedPhone] = useState(false);
	const [company, setCompany] = useState(null)
	const [address, setAddress] = useState(null)
	const [address1, setAddress1] = useState(null)
	const [city, setCity] = useState(null)
	const [code, setCode] = useState(null)
	const [desc, setdesc] = useState(null)
	const [longDesc, setLongDesc] = useState(null)
	const [state, setState] = useState(null)
	const [country, setCountry] = useState(null)
	const [website, setWebsite] = useState(null)
	const [profileImage, setProfileImage] = useState("")
	const [initLoc, setInitLoc] = useState([])
	const [initLocCp, setInitLocCp] = useState([])
	const [isLoad, setIsLoad] = useState(false)
	const [mapLoad, setMapLoad] = useState(false)
	const [checkMapLoad, setCheckMapLoad] = useState(false)
	const [showNext, setShowNext] = useState(false)
	const [getMeals, setGetMeals] = useState([])
	const [policyData, setPolicyData] = useState()
	const [allrecords, setAllRecords] = useState([])
	const [requirename, setRequireName] = useState(false)
	const [requireemail, setRequireEmail] = useState(false)

	const [expertise1, setExpertise1] = useState(false)
	const [expertise2, setExpertise2] = useState(false)
	const [expertise3, setExpertise3] = useState(false)
	const [expertise4, setExpertise4] = useState(false)
	const [expertise5, setExpertise5] = useState(false)
	const [expertise6, setExpertise6] = useState(false)
	const [expertise7, setExpertise7] = useState(false)
	const [expertise8, setExpertise8] = useState(false)
	const [expertise9, setExpertise9] = useState(false)
	const [expertise10, setExpertise10] = useState(false)
	const [expertise11, setExpertise11] = useState(false)
	const [expertise12, setExpertise12] = useState(false)
	const [expertise13, setExpertise13] = useState(false)
	const [expertise14, setExpertise14] = useState(false)
	const [expertise15, setExpertise15] = useState(false)
	const [experience_date, setYearsOfExperience] = useState('')
	const [customer_goodtoknows, setCustomerGoodToKnows] = useState('')

	const [expertiseData, setExpertiseData] = useState(null)

	const [selectedFile, setSelectedFile] = useState([])
	const [publishedDate, setPublishedDate] = useState("")
	const [createdDate, setCreatedDate] = useState("")
	const [guidePhoto, setGuidePhoto] = useState([])
	const [status1, setStatus1] = useState(true)
	const [status2, setStatus2] = useState(false)
	const [status3, setStatus3] = useState(false)
	const [rivers, setRivers] = useState([])
	const [riversCp, setRiversCp] = useState([])
	const [loadcount, setLoadCount] = useState(0);
	const [showSearchField, setShowSearchField] = useState(false)
	const [searchRivers, setSearchRivers] = useState([])
	const [verification, setVerification] = useState(false);
	const [fullName, setFullName] = useState("");
	const [dob, setDob] = useState("");
	const [ssn, setSSN] = useState("");
	const [inValidSSN, setInValidSSN] = useState(false);
	const [invalidFullName, setInvalidFullName] = useState(false);
	const [formatedDob, setFormatedDob] = useState("");
	const [referralGuides, setReferralGuides] = useState([])
	const [zoomMap, setZoomMap] = useState(8)
	const [loadZoom, setloadZoom] = useState(false)
	const [reset, setReset] = useState(false)
	const [bounds, setBounds] = useState(null)
	const [guideRateCard, setGuideRateCard] = useState([]);
	const [fishingType, setFishingType] = useState(1)
	const [guideContext, setGuideContext] = useState(null)
	const [calendarData, setCalendarData] = useState(null)
	const [originalcalendarData, setOriginalCalendarData] = useState(null)
	const [waterListReset, setWaterListReset] = useState(false)
	const [path, setPath] = useState('')
	const [vehicle, setVehicle] = useState([]);
	const [greatGuide, setGreatGuide] = useState(false)
	const [bestSpots, setBestSpots] = useState(false)
	const [goodWithKids, setGoodWithKids] = useState(false)
	const [greatNovices, setGreatNovices] = useState(false)
	const [topAnglers, setTopAnglers] = useState(false)
	const [bestBoat, setBestBoat] = useState(false)
	const [recommendTrip, setRecommendTrip] = useState(true)
	const [acknowledge, setAcknowledge] = useState([])
	const [allRivers, setAllRivers] = useState([])
	const [riverMarker, setRiverMarker] = useState({})

	const [displayCalendar, setDisplayCalendar] = useState(true)
	const [selectedAreaList, setSelectedAreaList] = useState([])
	const [newBarDataList, setNewBarDataList] = useState([])
	const [showAddFishSpecies, setShowAddFishSpecies] = useState(false)
	const [editView, setEditView] = useState(false)
	const [marlin, setMarlin] = useState([])
	const [BSData, setBSData] = useState([])
	const [fishCalenderData, setFishCalenderData] = useState([])
	const [editedArea, setEditedArea] = useState([])
	const [jan, setJan] = useState(0)
	const [feb, setFeb] = useState(0)
	const [mar, setMar] = useState(0)
	const [apr, setApr] = useState(0)
	const [may, setMay] = useState(0)
	const [jun, setJun] = useState(0)
	const [jul, setJul] = useState(0)
	const [aug, setAug] = useState(0)
	const [sep, setSep] = useState(0)
	const [oct, setOct] = useState(0)
	const [nov, setNov] = useState(0)
	const [dec, setDec] = useState(0)
	const [areas, setAreas] = useState('No calendar created yet');
	const [bsMArlin, setbsMArlin] = useState(false);
	const [linkedGuides, setLinkedGuides] = useState([]);
	const [acceptedInvite, setAcceptedInvite] = useState(false)
	const [selectedInvite, setSelectedInvite] = useState({})
	const [bsAvailableGuideList, setBSAvailableGuideList] = useState([])
	const [isAllGuideAvailableList, setIsAllGuidesAvailable] = useState([])
	const [companyFullPayout, setCompanyFullPayout] = useState(false);
	const [selectedRateType, setSelectedRateType] = useState("");
	const [scubaFee, setScubaFee] = useState(false);
	const [bookedGuideList, setBookedGuideList] = useState([]);
	const [startCounter, setStartCounter] = useState(false);
	// useEffect(() => {
	// 	//getting the current location of the user
	// 	// if (navigator.geolocation) {
	// 	// 	navigator.geolocation.getCurrentPosition(position => {
	// 	// 		var currentPosition = {
	// 	// 			lat: position.coords.latitude,
	// 	// 			lng: position.coords.longitude
	// 	// 		}
	// 	// 		//set the current location
	// 	// 		setCurrentLoc(currentPosition)
	// 	// 	}, error => {
	// 	// 		var currentPosition = {
	// 	// 			lat: 27.6648,
	// 	// 			lng: -81.5158,
	// 	// 		}
	// 	// 		//set location to indian ocean
	// 	// 		setCurrentLoc(currentPosition)
	// 	// 	})
	// 	// }
	// }, []);

	return (
		<BeaGuideContext.Provider value={{
			businessSuiteEnabled, setBusinessSuiteEnabled,
			currentMarker, setCurrentMarker,
			statusResponse, setStatusResponse,
			showNext, setShowNext,
			currentLoc, setCurrentLoc,
			loadingMask, setLoadingMask,
			isLoading, setLoading,
			customLoadingMask, setCustomLoadingMask,
			isLoad, setIsLoad,
			initiated, setInitiated,
			profile, setProfile,
			location, setLocation,
			loc_1, setLoc_1,
			loc_2, setLoc_2,
			loc_3, setLoc_3,
			equipments, setEquipments,
			allrecords, setAllRecords,
			extras, setExtras,
			riverMarker, setRiverMarker,
			searchRivers, setSearchRivers,
			ready_to_submit, setReady_to_submit,
			submitted, setSubmitted,
			approved, setApproved,
			apiError, setApiError,
			polyline, setPolyline,
			searchedCity, setSearchedCity,
			name, setName,
			email, setEmail,
			phone, setPhone,
			formattedPhone, setFormattedPhone,
			company, setCompany,
			address, setAddress,
			address1, setAddress1,
			city, setCity,
			code, setCode,
			allRivers, setAllRivers,
			desc, setdesc,
			longDesc, setLongDesc,
			state, setState,
			country, setCountry,
			website, setWebsite,
			guidePOI, setGuidePOI,
			loadcount, setLoadCount,
			geoLocation, setGeoLocation,
			initLoc, setInitLoc,
			initLocCp, setInitLocCp,
			getMeals, setGetMeals,
			policyData, setPolicyData,
			showSearchField, setShowSearchField,
			//Validation
			requirename, setRequireName,
			requireemail, setRequireEmail,
			profileImage, setProfileImage,
			reset, setReset,
			expertise1, setExpertise1,
			expertise2, setExpertise2,
			expertise3, setExpertise3,
			expertise4, setExpertise4,
			expertise5, setExpertise5,
			expertise6, setExpertise6,
			expertise7, setExpertise7,
			expertise8, setExpertise8,
			expertise9, setExpertise9,
			expertise10, setExpertise10,
			expertise11, setExpertise11,
			expertise12, setExpertise12,
			expertise13, setExpertise13,
			expertise14, setExpertise14,
			expertise15, setExpertise15,

			expertiseData, setExpertiseData,
			mapLoad, setMapLoad,
			checkMapLoad, setCheckMapLoad,
			selectedFile, setSelectedFile,

			guidePhoto, setGuidePhoto,
			publishedDate, setPublishedDate,
			createdDate, setCreatedDate,
			status1, setStatus1,
			status2, setStatus2,
			status3, setStatus3,
			rivers, setRivers,
			riversCp, setRiversCp,
			verification, setVerification,
			fullName, setFullName,
			dob, setDob,
			ssn, setSSN,
			inValidSSN, setInValidSSN,
			invalidFullName, setInvalidFullName,
			formatedDob, setFormatedDob,
			zoomMap, setZoomMap,
			loadZoom, setloadZoom,
			geoLocationCp, setGeoLocationCp,
			bounds, setBounds,
			guideRateCard, setGuideRateCard,
			guideDetails, setGuideDetails,
			fishingType, setFishingType,
			guideContext, setGuideContext,
			waterListReset, setWaterListReset,
			experience_date, setYearsOfExperience,
			customer_goodtoknows, setCustomerGoodToKnows,
			title, setTitle,
			show_title, setShowTitle,
			path, setPath,
			territoryName, setTerritoryName,
			greatGuide, setGreatGuide,
			bestSpots, setBestSpots,
			goodWithKids, setGoodWithKids,
			greatNovices, setGreatNovices,
			topAnglers, setTopAnglers,
			bestBoat, setBestBoat,
			recommendTrip, setRecommendTrip,
			acknowledge, setAcknowledge,
			vehicle, setVehicle,

			displayCalendar, setDisplayCalendar,
			selectedAreaList, setSelectedAreaList,
			newBarDataList, setNewBarDataList,
			showAddFishSpecies, setShowAddFishSpecies,
			editView, setEditView,
			fishCalenderData, setFishCalenderData,
			editedArea, setEditedArea,
			jan, setJan,
			feb, setFeb,
			mar, setMar,
			apr, setApr,
			may, setMay,
			jun, setJun,
			jul, setJul,
			aug, setAug,
			sep, setSep,
			oct, setOct,
			nov, setNov,
			dec, setDec,
			areas, setAreas,
			bsMArlin, setbsMArlin,
			calendarData, setCalendarData,
			originalcalendarData, setOriginalCalendarData,
			linkedGuides, setLinkedGuides,
			referralGuides, setReferralGuides,
			acceptedInvite, setAcceptedInvite,
			marlin, setMarlin,
			BSData, setBSData,
			selectedInvite, setSelectedInvite,
			bsAvailableGuideList, setBSAvailableGuideList,
			isAllGuideAvailableList, setIsAllGuidesAvailable,
			companyFullPayout, setCompanyFullPayout,
			selectedRateType, setSelectedRateType,
			scubaFee, setScubaFee,
			bookedGuideList, setBookedGuideList,
			startCounter, setStartCounter,
		}}
		>
			{props.children}
		</BeaGuideContext.Provider>
	)
}

export default BeaGuideProvider
